<template>
  <div class="notice">
    <!--    <van-tabs v-model="activeName" class="tab" title-active-color="#027eff" color="#027eff" @click="tabChange"@click="tabChange">-->
    <!--      <van-tab name = '0' title="居民问卷"></van-tab>-->
    <!--      <van-tab name = '1' title="社工问卷"></van-tab>-->
    <!--      <van-tab name = '2' title="我的问卷"></van-tab>-->
    <!--    </van-tabs>-->
    <form action="/">
      <van-search v-model="searchValue" show-action placeholder="请输入搜索问卷标题" @search="onSearch" @cancel="onCancel"/>
    </form>
<!--    <form action="/">-->
<!--      <van-search-->
<!--          v-model="searchValue"-->
<!--          show-action-->
<!--          label=""-->
<!--          placeholder="请输入搜索问卷标题"-->
<!--          @search="onSearch"-->
<!--          clearable-->
<!--      >-->
<!--        <template #action>-->
<!--          <div @click="onSearch"><van-button round type="info">搜索</van-button></div>-->
<!--        </template>-->
<!--      </van-search>-->
<!--    </form>-->
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#FE5E5E': '' }">创建时间<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#FE5E5E': '' }">问卷类型<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6">
          <p @click="judgeShow = !judgeShow" :style="{color:judgeShow?'#FE5E5E': '' }" v-if="activeName == '1' || activeName == '0'">是否匿名<van-icon name="arrow-down" /></p>
          <p @click="statusShow = !statusShow" :style="{color:judgeShow?'#FE5E5E': '' }" v-if="activeName == '2'">状态<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6" @click="moreCancel">重置</van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" :formatter="formatDate"
                           type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" :formatter="formatDate"
                           type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="typeShow" position="bottom"  >
      <van-picker title="问卷类型" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
    </van-popup>
    <van-popup v-model="judgeShow" position="bottom"  >
      <van-picker title="是否匿名" show-toolbar :columns="judgeList" value-key="label" @confirm="judgeConfirm" @cancel="judgeShow = false"  />
    </van-popup>
    <van-popup v-model="statusShow" position="bottom"  >
      <van-picker title="状态" show-toolbar :columns="statusList" value-key="label" @confirm="statusConfirm" @cancel="statusShow = false"  />
    </van-popup>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <van-cell class="total">
          <template #title>
            <div class="list_totalCount">共有<span style="color: #397ff4">{{totalCount}}条</span>数据</div>
          </template>
        </van-cell>
        <van-cell @click="goInfo(item.id, item.status, item.isAnonymous, item.participate, item.useTotal, item.shortName)" v-for="(item, index) in dataList" :key="index">
          <van-cell-group >
            <van-cell class="title" center>
              <van-row>
                <van-col :span="18"><span style="color: #3B81F4">{{item.announcer}}</span> | {{item.createTimeStr}}</van-col>
                <van-col :span="6" :style="{color: item.status == 1? '#2ada85' : '#ea4b43'}">{{item.statusStr}}</van-col>
              </van-row>
              <van-row>
                <van-col :span="6"><img :src="icon"/></van-col>
                <van-col :span="14" >{{item.shortName}}<span>({{item.isAnonymousStr}})</span></van-col>
                <van-col :span="4" >详情 <van-icon name="arrow" /></van-col>
                <van-col :span="8" >{{item.typeStr}}{{item.useTotal != null ? '/' + item.useTotal + '人' : ''}}</van-col>
              </van-row>
            </van-cell>
          </van-cell-group>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import {getDictTree} from '@/utils/common'
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
export default {
  components :{
    topBar,
    getDictTree,
    formatterDate
  },
  data() {
    return {
      activeName: '0',
      loading: false,
      finished: false,
      totalCount: 0,
      limit: 10,
      page: 0,
      dataList: [],
      searchValue: '',
      createTime: '',
      maxDate: '',
      isAnonymous: '',
      type: '',
      status: '',
      id: 0,
      typeName: '',
      judgeShow: false,
      statusShow: false,
      beginDateShow: false,
      endDateShow: false,
      dateBarShow: false,
      typeShow: false,
      typeList : [],
      judgeList: [{ value: 0, label: "匿名" }, { value: 1, label: "实名" }],
      statusList: [{ value: 1, label: "进行中" }, { value: 2, label: "已完成" }],
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      icon: require('@/assets/img/book.png')
    }
  },
  methods: {
    goInfo(id, status, isAnonymous, participate, useTotal, shortName) {
      this.$router.push({path: '/questionnaire', query: {id, isAnonymous, status, participate, useTotal, shortName}})
      // if (id && status == 1) {
      //   // this.$router.push({path: '/questionnaire', query: {id, participate, isAnonymous}})
      //   if (this.activeName == '0'){
      //     this.$router.push({path: '/questionnaire', query: {id, isAnonymous, status, participate}})
      //   } else if (this.activeName == '2') {
      //     this.$router.push({path: '/questionnaire', query: {id, participate: 'true', isAnonymous, status}})
      //   }
      // } else if(id && status == 2){
      //   if (this.activeName == '0'){
      //     this.$router.push({path: '/questionnaire', query: {id, isAnonymous, status, participate}})
      //   } else {
      //     this.$router.push({path: '/questionnaire', query: {id, isAnonymous, status}})
      //   }
      // }
    },
    getDict() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({code:'questionnaireType'},false)
      }).then(({data})=> {
        if (data.code == 0) {
          for (let i in data.dicts) {
            let dict = data.dicts[i];
            dict["label"] = dict.label;
            dict["value"] = dict.value + "";
          }
          this.typeList = data.dicts
        }
      })
    },
    //问卷列表
    getDataList(status) {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/questionnaire/publicity`),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: this.page,          //当前页码
          limit: this.limit,        //每页展示的记录数
          name: this.searchValue, // 搜索框内容,以名字作为查询条件
          userId: this.$globalData.userInfo.userId,
          orgId: this.$orgId,
          surveyObject: 1,
          type: this.type,
          isAnonymous: this.isAnonymous,
          beginTime: this.selectDate.beginDate,
          endTime: this.selectDate.endDate,
          self: this.activeName == '2' ? 1 : '',
          status: this.status || ''
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataList = this.dataList.concat(data.page.list)
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        // this.$toast.fail(data.msg);
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    onSearch(val) {
      this.page = 0
      this.searchValue = val
      this.dataList = []
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.finished = false
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
    },

    // 日期选项格式化
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
      this.dataList = []
      this.page = 0
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
      this.dateBarShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.finished = false
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
    },
    // 问卷类型选择
    typeConfirm (value) {
      this.dataList = []
      this.type = value.value
      this.page = 0
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
      this.typeShow = false
    },
    // 是否匿名选择
    judgeConfirm (value) {
      this.dataList = []
      this.isAnonymous = value.value
      this.page = 0
      this.getDataList()
      this.judgeShow = false
    },
    //状态选择
    statusConfirm (value) {
      this.dataList = []
      this.status = value.value
      this.page = 0
      this.getDataList(1)
      this.statusShow = false
    },
    moreCancel () {
      this.type = ''
      this.createTime = ''
      this.isAnonymous = ''
      this.status = ''
      this.searchValue = ''
      this.page = 0
      this.dataList = []
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
    },
    tabChange (value) {
      this.dataList = []
      this.page = 0
      this.finished = false
      this.loading = false
      if (this.activeName == '2') {
        this.getDataList(1)
      } else {
        this.getDataList()
      }
    },
  },
  created () {
    this.activeName = this.$route.query.type
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
    this.getDict()
  }
}
</script>
<style>
.van-button--round {
  height: 70px;
}
.van-button--normal {
  padding: 0 40px;
  font-size: 30px;
}
.van-search__content {
  border: 1px solid #eee;
  border-radius: 40px;
  background-color: #fff;
}
.van-search__action {
  line-height: 0;
}
.van-icon-search::before {
  content: '';
}
.van-tab  {
  color: #999999;
  border-right: 1px solid #ccc;
}
.van-tab:nth-child(3) {
  border-right: 0;
}

</style>
<style lang="scss" scoped>
.filterBar,.dateBar {
  padding-left: 15px;
  p {
    margin: 0;
  }
}
.notice {
  padding-top: 100px;
  .tab {
    //border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .total {
      background-color: #f5f5f5;
      padding-left: 10px;
      margin-top: 0;
    }
    .list_totalCount {
      color: #000;
    }
    .van-cell {
      position: relative;
      //margin-bottom: 30px;
      border-radius: 10px;
      .title {
        padding: 15px 15px;
        margin-bottom: 5px;
        .van-row:nth-child(1) .van-col:nth-child(2){
          color: #1881fe;
          font-size: 30px;
        }
        .van-row:nth-child(1) .van-col:nth-child(2){
          padding-left: 30px;
        }
        .van-row:nth-child(2) .van-col:nth-child(1){
          font-size: 32px;
          img{
            width: 120px;
            height: 120px;
          }
        }
        .van-row:nth-child(2) {
          margin-top: 60px;
        }
        .van-row:nth-child(2) .van-col:nth-child(2){
          font-weight: 600;
          padding-top: 10px;
          span{
            font-size: 26px;
            font-weight: 600;
            //color: #ea4b43;
          }
        }
        .van-row:nth-child(2) .van-col:nth-child(3){
          font-size: 24px;
          color: #969696;
          padding-left: 10px;
          margin-top: 30px;
        }
        .van-row:nth-child(2) .van-col:nth-child(4){
          font-size: 28px;
          color: #858585;
          margin-bottom: 10px;
        }
        .van-row:nth-child(3) .van-col:nth-child(1){
          font-size: 32px;
        }
      }
    }
    [class*=van-hairline]::after {
      border: none;
    }
    .van-cell::after {
      border-bottom: none;
    }
  }
}
</style>
